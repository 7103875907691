import React, { useState } from "react";
import { Markup } from "interweave";
import moment from "moment-timezone";

import { ingeniuxURL } from "../../config/urls";
import "./index.css";

export const FranchiseOffers = (props) => {
    const { contentData = {} } = props;
    const [showModal, setShowModal] = useState("none");
    const [currentModal, setCurrentModal] = useState();
    const [phoneValue, setPhoneValue] = useState("");
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [emailValue, setEmailValue] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [zipcodeValue, setZipcodeValue] = useState("");
    const [isValidZipcode, setIsValidZipcode] = useState(true);
    const [fullnameValue, setFullnameValue] = useState("");
    const [isPopulatedFullname, setIsPopulatedFullname] = useState(true);
    const [isErrorFree, setIsErrorFree] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [wasModalClosed, setWasModalClosed] = useState(false);


    const handlePhoneChange = (e) => {
        setPhoneValue(e.target.value);
    }
    const handlePhoneFocus = () => {
        setIsValidPhone(true);
        setPhoneValue("");
    }

    const handleEmailChange = (e) => {
        setEmailValue(e.target.value);
    }
    const handleEmailFocus = () => {
        setIsValidEmail(true);
        setEmailValue("");
    }

    const handleZipcodeChange = (e) => {
        setZipcodeValue(e.target.value);
    }
    const handleZipcodeFocus = () => {
        setIsValidZipcode(true);
        setZipcodeValue("");
    }

    const handleFullnameChange = (e) => {
        setFullnameValue(e.target.value);
    }
    const handleFullnameFocus = () => {
        setIsPopulatedFullname(true);
        setFullnameValue("");
    }

    const handleOfferSubmit = (e) => {
        // prevent page from refreshing
        e.preventDefault();
        const isValidPhone = /(^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$)/.test(phoneValue);
        const isValidEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(emailValue);
        const isValidZipcode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcodeValue);
        if (!isValidPhone || phoneValue === "") {
            setIsValidPhone(false);
            setPhoneValue(contentData?.PhoneNumberLabelText?.Value);
        }
        if (!isValidEmail || emailValue === "") {
            setIsValidEmail(false);
            setEmailValue(contentData?.EmailAddressLabelText?.Value);
        }
        if (!isValidZipcode || zipcodeValue === "") {
            setIsValidZipcode(false);
            setZipcodeValue(contentData?.ZipCodeLabelText?.Value);
        }
        if (fullnameValue === "") {
            setIsPopulatedFullname(false);
            setFullnameValue(contentData?.FullNameLabelText?.Value);
        }

        if (isValidPhone === false || isValidEmail === false || isPopulatedFullname === false || isValidZipcode === false) {
            setIsErrorFree(false);
        } else {
            setIsErrorFree(true);
            sendToYotrack(fullnameValue, phoneValue, zipcodeValue, emailValue, contentData?.Offer[currentModal]?.OfferName?.Value, contentData?.Offer[currentModal]?.OfferDescription?.Value);
            setIsFormSubmitted(true);
        }
    }

    const handleOfferModalToggle = (e, key) => {
        e.preventDefault();
        setCurrentModal(key);
        if (showModal === "none") {
            setShowModal("block");
        } else {
            setShowModal("none");
        }
    }

    function sendToYotrack(formName, formPhone, formZipcode, formEmail, formOfferName, formOfferDescription) {
        window.YoTrack("handymanmatters", contentData?.corp?.FranchiseID?.Value, function (err, api) {

            console.log("err: ", err); // null means success. anything else is a failure
            console.log("api: ", api); // object containing yotrack methods

            const jsondata = { name: formName, phone: formPhone, zipcode: formZipcode, email: formEmail, offerType: formOfferName, offerDescription: formOfferDescription };

            api.trackData(jsondata, function (err, data) {
                // Payload submission complete
                console.log("data: ", data); //Payload sent to dashboard as JSON object
            })

        })
    }

    const printOffer = () => {
        var contents = document.getElementsByClassName("offer-print-body")[0].innerHTML;
        var frame1 = document.createElement('iframe');
        frame1.name = "frame1";
        frame1.style.position = "absolute";
        frame1.style.top = "-1000000px";
        document.body.appendChild(frame1);
        var frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
        frameDoc.document.open();
        frameDoc.document.write('</head><body><div>');
        frameDoc.document.write(contents);
        frameDoc.document.write('</body></html></div>');
        frameDoc.document.close();
        setTimeout(function () {
            window.frames["frame1"].focus();
            window.frames["frame1"].print();
            document.body.removeChild(frame1);
        }, 500);
        return false;
    }


    var today = new Date().toISOString().split('T')[0];

    return (
        <>

            {/* checking if there is an Offer array in api response */}
            {contentData?.Offer ?
                <>
                    <div className="offers-content-wrap">
                        <div className="container row">
                            <div className="offers-wrap">
                                {
                                    contentData?.Offer[0] ?

                                        <div className="col-sd-12 col-dd-8 pad-right-lg">
                                            <div className="offer" id="offer-wrap-1">
                                                <div className="offers-module" style={{ background: `#fff url(${contentData?.BackgroundImage?.Value && `${ingeniuxURL}${contentData?.BackgroundImage?.Value}`}) center center repeat` }}>
                                                    <div className="offer-before" style={{ background: `transparent url(${contentData?.FeaturedOfferLogo?.Value && `${ingeniuxURL}${contentData?.FeaturedOfferLogo?.Value}`}) top left no-repeat` }}></div>
                                                    <div className="offer-after" style={{ background: `transparent url(${contentData?.FeaturedOfferImage?.Value && `${ingeniuxURL}${contentData?.FeaturedOfferImage?.Value}`}) top right no-repeat` }}></div>
                                                    <div className="module-head">
                                                        <div className="offer-module-heading offer-heading">
                                                            {contentData?.Offer[0]?.OfferName?.Value}
                                                        </div>
                                                    </div>
                                                    <div className="module-body">
                                                        <div className="offer-headline offer-text">
                                                            {contentData?.Offer[0]?.OfferDescription?.Value}
                                                        </div>
                                                        <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 0)}>
                                                            {contentData?.ClaimOfferButtonText?.Value}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="col-sd-12 col-dd-8 pad-right-lg">
                                            <div className="offer" id="offer-wrap-1">
                                                <div className="offers-module" style={{ background: `#fff url(${contentData?.BackgroundImage?.Value && `${ingeniuxURL}${contentData?.BackgroundImage?.Value}`}) center center repeat` }}>
                                                    <div className="offer-before" style={{ background: `transparent url(${contentData?.FeaturedOfferLogo?.Value && `${ingeniuxURL}${contentData?.FeaturedOfferLogo?.Value}`}) top left no-repeat` }}></div>
                                                    <div className="offer-after" style={{ background: `transparent url(${contentData?.FeaturedOfferImage?.Value && `${ingeniuxURL}${contentData?.FeaturedOfferImage?.Value}`}) top right no-repeat` }}></div>
                                                    <div className="module-head">
                                                        <div className="offer-module-heading offer-heading">
                                                            {contentData?.Offer?.OfferName?.Value}
                                                        </div>
                                                    </div>
                                                    <div className="module-body">
                                                        <div className="offer-headline offer-text">
                                                            {contentData?.Offer?.OfferDescription?.Value}
                                                        </div>
                                                        <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 0)}>
                                                            {contentData?.ClaimOfferButtonText?.Value}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                }



                                <div className="col-sd-12 col-md-6 col-dd-4 right-col">
                                    {
                                        contentData?.Offer[1] &&
                                        <div className="offer" id="offer-wrap-2">
                                            <div className="offers-module" style={{ background: `#fff url(${contentData?.BackgroundImage?.Value && `${ingeniuxURL}${contentData?.BackgroundImage?.Value}`}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {contentData?.Offer[1]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {contentData?.Offer[1]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 1)}>
                                                        {contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        contentData?.Offer[2] &&
                                        <div className="offer" id="offer-wrap-3">
                                            <div className="offers-module" style={{ background: `#fff url(${contentData?.BackgroundImage?.Value && `${ingeniuxURL}${contentData?.BackgroundImage?.Value}`}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {contentData?.Offer[2]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {contentData?.Offer[2]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 2)}>
                                                        {contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                                {
                                    contentData?.Offer[3] &&
                                    <div className="col-sd-12 col-md-6 col-dd-6 pad-right-lg">
                                        <div className="offer" id="offer-wrap-4">
                                            <div className="offers-module" style={{ background: `#fff url(${contentData?.BackgroundImage?.Value && `${ingeniuxURL}${contentData?.BackgroundImage?.Value}`}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {contentData?.Offer[3]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {contentData?.Offer[3]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 3)}>
                                                        {contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    contentData?.Offer[4] &&
                                    <div className="col-sd-12 col-md-6 col-dd-6">
                                        <div className="offer" id="offer-wrap-5">
                                            <div className="offers-module" style={{ background: `#fff url(${contentData?.BackgroundImage?.Value && `${ingeniuxURL}${contentData?.BackgroundImage?.Value}`}) center center repeat` }}>
                                                <div className="module-head">
                                                    <div className="offer-module-heading offer-heading">
                                                        {contentData?.Offer[4]?.OfferName?.Value}
                                                    </div>
                                                </div>
                                                <div className="module-body">
                                                    <div className="offer-headline offer-text">
                                                        {contentData?.Offer[4]?.OfferDescription?.Value}
                                                    </div>
                                                    <button className="button claim-button" onClick={(e) => handleOfferModalToggle(e, 4)}>
                                                        {contentData?.ClaimOfferButtonText?.Value}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <div id="offer-modal-192074-yoModalized" className="yo-modal-container" style={{ display: `${showModal}` }}>
                        <div className="modal text-and-image enhanced yo-lightbox" dataTemplateType="text-and-image" style={{ width: '549.5px', display: 'block', top: '672.7px', border: 'none' }} id="offer-modal-192074">
                            <div className="yo-lightbox-close" style={{ zIndex: '52', cursor: 'pointer' }}>x</div>

                            <div className="offer-preview-container text-and-image">

                                <div className="offer-preview-header">
                                    <a className="close-button" onClick={() => [setShowModal("none"), setWasModalClosed(true)]}><i className="icon-close">X</i></a>
                                    <a target="_blank" href="tel:4057854550" className="call-business-phone" type="submit">
                                        CALL {contentData?.corp?.PhoneNumber?.Value}
                                    </a>
                                    <a style={{
                                        marginRight: '35px'
                                    }} target="_blank"
                                        onClick={printOffer}
                                        className="print-offer-button" type="submit"
                                    >PRINT OFFER</a>
                                   
                                    <a target="_blank" href="#" className="call-business-phone-during-hours" type="submit" style={{ display: 'none' }}></a>
                                </div>

                                {/* <!-- Error message if any--> */}
                                <div className="error-message" style={{ display: `${isPopulatedFullname && isValidEmail && isValidPhone && isValidZipcode ? 'none' : 'block'}` }}>
                                    <p className="error-message-text" style={{ color: '#FF0000', display: 'block', fontFamily: 'Open Sans, Arial, Helvetica, sans-serif', fontSize: '17px', textAlign: 'center' }}>
                                        {!isPopulatedFullname ? "Please enter your full name" :
                                            !isValidPhone ? "Please enter a valid 10 digit phone number" :
                                                !isValidZipcode ? "Please enter a valid US zip code" :
                                                    !isValidEmail && "Please enter a valid email address"}
                                    </p>
                                </div>

                                <div className="offer-print-body">
                                    <div className="offer-preview-body">
                                        <div className="left" style={{ width: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && '100%'}` }}>
                                            <h6 className="offer-heading offer-type-class DOLLARS_OFF" style={{ display: 'block', fontSize: '2em', marginTop: '0.67em', marginBottom: '0.67em', marginLeft: '0', marginRight: '0', fontWeight: 'bold', textAlign: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                {contentData?.Offer[currentModal]?.OfferName?.Value}
                                            </h6>
                                            <p className="offer-headline offer-text" style={{ display: 'block', fontSize: '1.5em', marginTop: '0.83em', marginBottom: '0.83em', marginLeft: '0', marginRight: '0', fontWeight: 'bold', textAlign: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                {contentData?.Offer[currentModal]?.OfferDescription?.Value}
                                            </p>

                                            <p className="expire-date" style={{ textAlign: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                {/* Offer Ends August 27, 2022 */}
                                            OFFER ENDS{" "}

                                                {moment(contentData?.Offer[currentModal]?.OfferExpirationDate?.Value, "YYYYMMDD").format("MMMM D, YYYY")}

                                            </p>

                                            <div className="address-block business-info" style={{ textAlign: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                <p className="business-name" style={{ textAlign: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>Ace Handyman Services{" "}
                                                    {contentData?.corp?.FranchiseName?.Value}
                                                </p>
                                                <p className="business-address" style={{ textAlign: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                    {contentData?.corp?.Address?.Value}
                                                    <br />
                                                    {contentData?.corp?.City?.Value}{", "}
                                                    {contentData?.corp?.StateAbbreviation?.Value}{" "}
                                                    {contentData?.corp?.ZipCode?.Value}
                                                </p>
                                                <p className="phone-number" style={{ textAlign: `${contentData?.Offer[currentModal]?.OfferImage?.Value === undefined && 'center'}` }}>
                                                    <span className="unpaid-number">
                                                        {contentData?.corp?.PhoneNumber?.Value}
                                                    </span>
                                                </p>
                                            </div>

                                            {contentData?.Offer[currentModal]?.OfferImage?.Value &&
                                                <div className="right">

                                                    <div className="offer-image-preview-container has-image">
                                                        <div className="offer-image">
                                                            {contentData?.Offer[currentModal]?.OfferImage?.Value && <img
                                                                alt={contentData?.Offer[currentModal]?.OfferImage?.AlternateText}
                                                                title={contentData?.Offer[currentModal]?.OfferImage?.AlternateText}
                                                                src={`${ingeniuxURL}${contentData?.Offer[currentModal]?.OfferImage?.Value}`}
                                                                width="300" height="200"
                                                            />}
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="offer-preview-terms-and-conditions">
                                        <p className="fine-print-text" style={{ textAlign: "center" }}>
                                            <Markup content={contentData?.Offer[currentModal]?.OfferTermsandConditions?.Value} />
                                            {/* Let us complete that “To-Do List” for you! Reserve a four (4) hour block of time for our professional craftsman to complete work around your home. Call today for easy and convenient scheduling. Guarantee included on all labor and materials purchase on your behalf. Four-hour block must be used in one trip and cannot be split. Price includes labor only, any needed materials will be billed separately. Cannot be combined with any other offers. Void where prohibited, restrictions may apply. Contact our office for further details. */}
                                        </p>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <div className="yo-modal-overlay" style={{ position: 'fixed', top: '0px', left: '0px', margin: '0px', padding: '0px', width: '100%', height: '100%', zIndex: '997' }}></div>
                    </div>
                </>

                :

                <div className="offers-content-wrap">
                    <div className="container row">
                        <div className="offers-wrap">
                            <div className="no-offers">
                                <div className="heading">
                                    Check back soon for more offers
                                </div>
                                <p style={{ textAlign: 'center' }}>
                                    Or contact us today to find out how we can help <strong>you</strong> love <strong>your</strong> home.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            }


        </>
    )
}

export default FranchiseOffers;